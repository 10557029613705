<template>
  <div class="form_box">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" :rules="rules">
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model="ruleForm.roleName" placeholder="请输入角色名称"></el-input>
      </el-form-item>
      <el-form-item label="角色状态" prop="status">
        <el-radio-group v-model="ruleForm.status">
          <el-radio label="0">正常</el-radio>
          <el-radio label="1">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from "@/api/index";
import debounce from 'lodash/debounce';
export default {
  name: "edit-from",
  props: {
    operateBox: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ruleForm: {
        roleName: "",
        status: "0",
      },
      rules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    if (this.operateBox.operate == "edit") {
      this.ruleForm = Object.assign({}, this.operateBox.obj);
    }
  },
  methods: {
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.operateBox.operate == "add") {
            this.addRole();
          }
          if (this.operateBox.operate == "edit") {
            this.editRole();
          }
        } else {
          return false;
        }
      });
    }, 500),
    //新增角色
    async addRole() {
      let p = {
        roleName: this.ruleForm.roleName,
        status: this.ruleForm.status,
        roleKey: this.ruleForm.roleName,
        roleSort: this.total + 1,
        menuIds: [],
      };
      let res = await api.addRole(p);
      if (res.code == 200) {
        this.$message.success("添加角色成功");
        this.$emit("close");
      } else {
        this.$message.error(res.msg);
      }
    },
    //修改角色
    async editRole() {
      let p = {
        roleId: this.ruleForm.roleId,
        roleName: this.ruleForm.roleName,
        status: this.ruleForm.status,
        roleKey: this.ruleForm.roleName,
        roleSort: this.ruleForm.roleSort,
        menuIds: [],
      };
      let res = await api.editRole(p);
      if (res.code == 200) {
        this.$message.success("修改角色成功");
        this.$emit("close");
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style scoped></style>
