<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>角色设置</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box"></div>
      <div class="operate_btn">
        <el-button type="primary" icon="el-icon-plus" @click="addTest"></el-button>
        <el-button type="primary" icon="el-icon-delete" @click="deleteTest"></el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" height="100%" ref="multipleTable">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column fixed prop="roleId" label="ID" width="140" />
        <el-table-column fixed prop="roleName" label="角色名称" width="700" />
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column width="100" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button type="primary" icon="el-icon-edit" circle @click="editTest(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" icon="el-icon-delete" circle @click="deleteInformation(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size="pageBox.pageSize"
      layout="total,sizes, prev, pager, next" :total="pageBox.total">
    </new-pagination>
    <!--新增编辑小组-->
    <el-dialog :visible.sync="editDialog" width="30%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from v-if="editDialog" :operateBox="operateBox" :total="total" @close="close"></edit-from>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/index";
import EditFrom from "./components/edit-from";
export default {
  components: { EditFrom },
  data() {
    return {
      tableData: [],
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      total: null,
      editDialog: false,
      operateBox: {
        title: "添加角色",
        operate: "add",
        obj: {},
      },
    };
  },
  methods: {
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val;
      this.getRoleList();
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val;
      this.getRoleList();
    },
    // 新增样例
    addTest() {
      this.operateBox = {
        title: "添加角色",
        operate: "add",
        obj: {},
      };
      this.editDialog = true;
    },
    editTest(row) {
      this.operateBox = {
        title: "编辑角色",
        operate: "edit",
        obj: row,
      };
      this.editDialog = true;
    },
    //删除弹框
    deleteTest() {
      if (this.$refs.multipleTable.selection.length == 0) {
        this.$alert("请选择删除项", "信息", {
          confirmButtonText: "确定",
        });
      } else {
        let arr = [];
        this.$refs.multipleTable.selection.forEach((item) => {
          arr.push(item.roleId);
        });

        this.delMenu(arr);
      }
    },
    // 删除角色
    deleteInformation(row) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let arr = [row.roleId];
          this.delMenu(arr);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //获取角色列表
    async getRoleList() {
      let res = await api.getRoleList(this.pageBox);
      if (res.code == 200) {
        this.tableData = res.rows;
        this.pageBox.total = res.total;
        this.total = res.total;
      } else {
        this.tableData = [];
        this.total = 0;
      }
    },
    close() {
      this.editDialog = false;
      this.getRoleList();
    },
    //删除角色
    async delMenu(arr) {
      let p = {
        ids: arr,
      };
      let res = await api.delRole(p);
      if (res.code == 200) {
        this.$message.success("删除角色成功");
        this.getRoleList();
      } else {
        this.$message.error(res.msg);
      }
    },
  },
  created() {
    this.getRoleList();
  },
};
</script>

<style scoped lang="less">
/*页面特殊样式*/
.one_tag,
.two_tag,
.three_tag {
  padding: 5px 12px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  color: #fff;
}

.one_tag {
  background: #c60c00;
  background: -webkit-linear-gradient(to bottom, #eb1610, #c66334);
  background: linear-gradient(to bottom, #eb1600, #c65f00);
}

.two_tag {
  background: #eb6601;
  background: -webkit-linear-gradient(to bottom, #eb6601, #c6aa07);
  background: linear-gradient(to bottom, #eb6601, #c6aa07);
}

.three_tag {
  background: #ebd007;
  background: -webkit-linear-gradient(to bottom, #ebd007, #bec606);
  background: linear-gradient(to bottom, #ebd007, #bec606);
}
</style>
